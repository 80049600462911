const permission = JSON.parse(localStorage.getItem("userData"))
  ? JSON.parse(localStorage.getItem("userData")).authentication
  : {};

const MenuItems = [
  // {
  //   menu_title: "Dashboard",
  //   path: "/app/dashboard",
  //   icon: "dashboard",
  //   category: "general",
  //   isMenuOpen: false,
  //   menu: "dashboard",
  //   fullPageMenu: false,
  //   desc: true,
  //   content: "",
  //   child_routes: null,
  //   permission:
  //     permission && permission.Dashboard !== undefined
  //       ? permission && permission.Dashboard.includes("Dashboard")
  //       : false,
  // },
  {
    menu_title: "Master",
    path: "/app/master/",
    icon: <i className="fa fa-cube" aria-hidden="true"></i>,
    category: "modules",
    isMenuOpen: false,
    menu: "master",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission?.Master !== undefined ? permission.Master.length > 0 : false,
    child_routes: [
      {
        path: "/app/master/user",
        menu_title: "User",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission?.Master && permission?.Master !== undefined
            ? permission &&
            permission?.Master &&
            permission?.Master?.includes("User_Master")
            : false,
      },

      {
        path: "/app/master/banner",
        menu_title: "Banner",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Banner_master")
            : false,
      },
      {
        path: "/app/master/announcement",
        menu_title: "Announcement",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Announcement_Master")
            : false,
      },

      {
        path: "/app/master/employee",
        menu_title: "Employee",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Employee_master")
            : false,
      },

      {
        path: "/app/master/download-category",
        menu_title: "Download Category",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission &&
            permission.Master.includes("Download_Category_Master")
            : false,
      },
      {
        path: "/app/master/download-section",
        menu_title: "Download Section",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission &&
            permission.Master.includes("Download_Section_Master")
            : false,
      },
      {
        path: "/app/master/department",
        menu_title: "Department",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Department_master")
            : false,
      },
      {
        path: "/app/master/vertical",
        menu_title: "Vertical",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Vertical_Master")
            : false,
      },

      {
        path: "/app/master/contact-card",
        menu_title: "Contact Card",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Contact_Card_Master")
            : false,
      },
      {
        path: "/app/master/ticket-category",
        menu_title: "Ticket Category",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Ticket_Category_Master")
            : false,
      },
      {
        path: "/app/master/topic-name",
        menu_title: "Topic Name",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Topic_Name_Master")
            : false,
      },
      {
        path: "/app/master/topic-media",
        menu_title: "Topic Media",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Topic_Media_Master")
            : false,
      },
      {
        path: "/app/master/faq",
        menu_title: "FAQ",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission:
          permission && permission.Master !== undefined
            ? permission && permission.Master.includes("Faq_Master")
            : false,
      },
      {
        path: "/app/master/town-hall",
        menu_title: "TownHall",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: true,
        // permission && permission.Master !== undefined
        //   ? permission && permission.Master.includes("Town_Hall_Master")
        //   : false,
      },
      {
        path: "/app/master/quick-links",
        menu_title: "IMP Links",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: true,
        // permission && permission.Master !== undefined
        //   ? permission && permission.Master.includes("Town_Hall_Master")
        //   : false,
      },
      {
        path: "/app/master/induction-master",
        menu_title: "Induction Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: true,
        // permission && permission.Master !== undefined
        //   ? permission && permission.Master.includes("Town_Hall_Master")
        //   : false,
      },
    ],
  },
  // {
  //   menu_title: "Product",
  //   path: "/app/master/product",
  //   icon: <i className="fa fa-snowflake-o" aria-hidden="true"></i>,
  //   category: "products",
  //   isMenuOpen: false,
  //   menu: "product",
  //   fullPageMenu: false,
  //   desc: true,
  //   content: "",
  //   permission:
  //     permission && permission.ProductsMaster !== undefined
  //       ? permission && permission.ProductsMaster.includes("Products_master")
  //       : false,
  //   child_routes: null,
  // },

  // {
  //   menu_title: "Customers",
  //   path: "/app/views/customers",
  //   icon: <i className="fa fa-user" aria-hidden="true"></i>,
  //   category: "customer",
  //   isMenuOpen: false,
  //   menu: "customer",
  //   fullPageMenu: false,
  //   desc: true,
  //   content: "",
  //   permission:
  //     permission?.CustomersMaster !== undefined
  //       ? permission.CustomersMaster.includes("Customer_master")
  //       : false,
  //   child_routes: null,
  // },

  // {
  //   menu_title: "Material Management",
  //   path: "/app/materialManagement/",
  //   icon: <i className="fa fa-cube" aria-hidden="true"></i>,
  //   category: "materialManagement",
  //   isMenuOpen: false,
  //   menu: "materialManagement",
  //   fullPageMenu: false,
  //   desc: true,
  //   content: "",
  //   permission:
  //     permission?.MaterialManagement !== undefined
  //       ? permission?.MaterialManagement?.length > 0
  //       : false,
  //   child_routes: [
  //     {
  //       path: "/app/materialManagement/purchase",
  //       menu_title: "Purchase Inventory",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission:
  //         permission &&
  //         permission?.MaterialManagement &&
  //         permission?.MaterialManagement !== undefined
  //           ? permission &&
  //             permission?.MaterialManagement &&
  //             permission?.MaterialManagement?.includes("Purchase_Inventory")
  //           : false,
  //     },
  //     {
  //       path: "/app/materialManagement/purchase_report",
  //       menu_title: "Inventory Report",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission:
  //         permission &&
  //         permission?.MaterialManagement &&
  //         permission?.MaterialManagement !== undefined
  //           ? permission &&
  //             permission?.MaterialManagement &&
  //             permission?.MaterialManagement?.includes("Inventory_Report")
  //           : false,
  //     },
  //     {
  //       path: "/app/materialManagement/purchase_history",
  //       menu_title: "Purchase History",
  //       icon: "equalizer",
  //       fullPageMenu: false,
  //       isMenuOpen: false,
  //       third_child_routes: null,
  //       permission:
  //         permission &&
  //         permission?.MaterialManagement &&
  //         permission?.MaterialManagement !== undefined
  //           ? permission &&
  //             permission?.MaterialManagement &&
  //             permission?.MaterialManagement?.includes("Purchase_History")
  //           : false,
  //     },
  //   ],
  // },
  // {
  //   menu_title: "Orders History",
  //   path: "/app/reports/Orders",
  //   icon: <i className="fa fa-cart-arrow-down" aria-hidden="true"></i>,
  //   category: "order",
  //   isMenuOpen: false,
  //   menu: "order",
  //   fullPageMenu: false,
  //   desc: true,
  //   content: "",
  //   permission:
  //     permission && permission.OrderHistoryMaster !== undefined
  //       ? permission &&
  //         permission.OrderHistoryMaster.includes("Order_History_master")
  //       : false,
  //   child_routes: null,
  // },
  {
    menu_title: "Reports",
    path: "/app/reports/",
    icon: <i className="fa fa-cube" aria-hidden="true"></i>,
    category: "reports",
    isMenuOpen: false,
    menu: "reports",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission?.ReportMaster !== undefined
        ? permission.ReportMaster.length > 0
        : false,
    child_routes: [
      // {
      //   path: "/app/reports/Sales",
      //   menu_title: "Sales Report",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.ReportMaster !== undefined
      //       ? permission && permission.ReportMaster.includes("Sales_master")
      //       : false,
      // },
      {
        path: "/app/reports/Ticket",
        menu_title: "Ticket Report",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: true,
        // permission && permission.ReportMaster !== undefined
        //   ? permission && permission.ReportMaster.includes("Ticket_master")
        //   : false,
      },
      // {
      //   path: "/app/reports/AbundantCart",
      //   menu_title: "Abundant Cart Report",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.ReportMaster !== undefined
      //       ? permission &&
      //         permission.ReportMaster.includes("Abundant_Cart_Report")
      //       : false,
      // },
      // {
      //   path: "/app/reports/TrendingProduct",
      //   menu_title: "Trending Product Report",
      //   icon: "equalizer",
      //   fullPageMenu: false,
      //   isMenuOpen: false,
      //   third_child_routes: null,
      //   permission:
      //     permission && permission.ReportMaster !== undefined
      //       ? permission &&
      //         permission.ReportMaster.includes("Trending_Product_Report")
      //       : false,
      // },
    ],
  },
  {
    menu_title: "Settings",
    path: "/app/master/setting",
    icon: <i className="fa fa-cog" aria-hidden="true"></i>,
    category: "general",
    isMenuOpen: false,
    menu: "order",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission:
      permission && permission.SettingsMaster !== undefined
        ? permission && permission.SettingsMaster.includes("Setting_master")
        : false,
    child_routes: null,
  },
];

export default MenuItems;
